import React from "react";
import { graphql } from "gatsby";
import { useTranslation, Link } from "gatsby-plugin-react-i18next";

import Layout from "../../components/Layout";
import MetaComponents from "../../components/seo/MetaComponents";
import ArticleStructuredData from "../../components/seo/ArticleStructuredData";
import Article from "../../components/Article";
import ArticleHeader from "../../components/ArticleHeader";
import MortgageCalculator from "../../components/MortgageCalculator";
import { urls, extendUrlWithSourceVersion } from "../../constants/urls";
import BreadcrumbList from "../../components/seo/BreadcrumbList";

const Vorsorgewohnung = () => {
    const { t } = useTranslation();

    return (
        <Layout>
            <MetaComponents title={t("page.meta.title")} description={t("page.meta.description")} />
            <ArticleHeader h1Title="Vorsorgewohnung – Vom Geldwert zum Sachwert" showCalc={false} />
            <Article>
                <p>
                    Wie fast jede europäische Metropole wächst Wien kontinuierlich und das auf absehbare Zeit. Dabei
                    erreichen uns regelmäßig Nachrichten von städtischer Wohnungsknappheit. Die Baubranche boomt,
                    vielerorts wächst die Zahl der Baustellen für neue Wohnungskomplexe immer weiter. Gleichzeitig sind
                    die Finanzierungskonditionen für private Investoren so günstig wie seit Jahrzehnten nicht mehr. Die
                    Negativzinspolitik der EZB hält auch für österreichische Kreditnehmer:innen die Zinsen mit großer
                    Sicherheit unten. Umgekehrt gilt dies aber auch für die Zinsen auf Einlagen von Sparern. Für viele
                    ist dieser Rahmen ein triftiger Grund, sich mit dem Erwerb von Immobilien als Anlage oder
                    Investitionsmöglichkeit zu beschäftigen: Es geht also um die Vorsorgewohnung oder auch
                    Anlegerwohnung. Was überhaupt unter den beiden zu verstehen ist, welche Vorteile der
                    Immobilienerwerb als Anlage oder als Investition für die Zukunft bieten kann, aber auch, welche
                    Nachteile oder Risiken sich dabei verstecken – all dem gehen wir in diesem Artikel auf die Spur.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Anleger- und Vorsorgewohnung – Definition</h2>
                <p>
                    Bei der Anleger- oder Vorsorgewohnung handelt es sich um eine Eigentumswohnung, die nach dem Erwerb
                    nicht (unmittelbar) für eigene Wohnzwecke genutzt wird. Beide stellen damit eine der zentralen
                    Anlageoptionen dar, falls man sein Erspartes vom Geldwert zum Sachwert transferieren möchte.
                    Zwischen den beiden Begriffen gibt es aber feine Unterschiede. Wird unter der Anlegerwohnung für
                    gewöhnlich eine reine Kapitalanlage verstanden, die oft ohne Finanzierung getätigt wird, suggeriert
                    die Vorsorgewohnung etwas mehr. Hier geht es um den Vermögensaufbau -für viele vor allem mit
                    Hinblick auf die persönliche Altersvorsorge. Mit einem üblichen Eigenkapitalanteil von 20 bis 30
                    Prozent wird die Vorsorgewohnung über einen{" "}
                    <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                        Kredit
                    </Link>{" "}
                    erworben und daraufhin vermietet. Die Idee ist, dass die laufenden Mieteinnahmen dann zur Tilgung
                    der Schulden verwendet werden. Nach gänzlicher Tilgung des Kredits ist die Wohnung abbezahlt. Die
                    weiterhin laufenden Mieteinnahmen können dann etwa die Pension deutlich einkommenssicherer
                    gestalten, genauso wie man eine frei nutzbare Eigentumswohnung zu seinem Vermögen zählen darf.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Vorteile – was spricht für eine Vorsorgewohnung?</h2>
                <p>
                    Welche Vorteile hat eine Vorsorgewohnung? Diese Frage stellt sich auch oft in dem Rahmen, wenn sich
                    Anleger für ihre Altersvorsorge zwischen Investitionen am Finanz- oder Kapitalmarkt und dem
                    Immobilienmarkt entscheiden.
                </p>
                <ul>
                    <li>
                        <strong>Die derzeitige Situation:</strong> Grundsätzlich gilt: Wie eingangs beschrieben ist die
                        Nachfrage nach Wohnungsraum in österreichischen Städten ungebrochen hoch. Viele gehen daher
                        weiterhin von steigenden Mieten und – wenn auch nicht mehr ganz so rasant – steigenden
                        Immobilienpreisen aus. Vor allem attraktive Neubauprojekte werden derzeit ohne hohe
                        Maklerprovision angeboten. In Kombination mit den von Banken angebotenen günstigen
                        Finanzierungskonditionen ist die momentane Situation eine nach wie vor sehr günstige, um auch
                        als privater Anleger im Immobilienmarkt zu investieren und eine Vorsorgewohnung zu erwerben. Mit
                        der richtig ausgewählten Vorsorgewohnung ist die Wertstabilität durch den ja immer bestehenden
                        menschlichen Bedarf nach einem Dach über dem Kopf sowieso gegeben.
                    </li>
                    <li>
                        <strong>Krisensicherheit:</strong> Die Finanzkrise 2008 hat die Möglichkeit des großflächigen
                        Ausfalls von Investitionen am Finanzmarkt wieder ins Bewusstsein geholt. Gleichzeitig werden
                        Sparer nicht durch gute{" "}
                        <Link to="/artikel/zinsen/" target="_blank" rel="noreferrer noopener">
                            Zinsen
                        </Link>{" "}
                        fürs Sparen belohnt. Und auch in Österreich greift im Falle einer Finanzkrise die
                        Einlagensicherung nur für einen begrenzten Betrag. Der Erwerb von Vorsorgewohnungen als Anlage
                        erscheint hier attraktiv, denn legt man sein Erspartes vom Geldwert zum Sachwert - etwa einer
                        Immobilie ­– um, ist dieses von einer Finanzkrise nicht mehr bedroht. Der Grundbucheintrag ist
                        deutlich sicherer als Anleihen oder ein reines Aktienportfolio.
                    </li>
                    <li>
                        <strong>Inflationsgeschützt:</strong> Genauso wie bei Finanzkrisen gilt der Vorteil von
                        Vorsorgewohnungen gegenüber Anlagen am Kapitalmarkt im Falle einer{" "}
                        <Link to="/artikel/inflation/" target="_blank" rel="noreferrer noopener">
                            Inflation
                        </Link>
                        . Indexierte Mieteinnahmen, das heißt, dass die Höhe der Miete an den Preisindex der allgemeinen
                        Lebenshaltungskosten vertraglich gekoppelt wird, schützen den Vermieter vor dem Wertverlust der
                        entrichteten Miete. Der{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredit
                        </Link>{" "}
                        kann im Zweifel immer weiter abbezahlt werden, denn die Miete ist „wertsicher“. Am Finanzmarkt
                        aber werden die Renditen im Falle einer Inflation oft vom Wertverfall geschluckt.
                    </li>
                    <li>
                        <strong>Steuerliche Vorteile:</strong> Vorsorgewohnungen bergen, wenn man gewisse Dinge
                        beachtet, erhebliche steuerliche Vorteile. Beispielsweise können Umsatzsteuer und Werbungskosten
                        abgesetzt werden, was die Einsparung von größeren Beträgen bedeutet kann. Da die korrekte
                        steuerliche Behandlung von Vorsorgewohnungen ein umfangreiches Thema ist, haben wir alles
                        Relevante in einem eigenen Artikel gesammelt, den du hier finden kannst.
                    </li>
                    <li>
                        <strong>Ideale Ergänzung zu Anlagen am Kapitalmarkt:</strong> Hat man bereits Anlagen am
                        Kapitalmarkt getätigt, stellt es sich als sehr sinnvoll heraus, den Erwerb von Immobilien als
                        Vorsorgewohnung zur Diversifikation seines Portfolios zu nutzen. Denn steigen die Zinsen,
                        generieren die Anlagen am Finanzmarkt höhere Rendite, bei einer Inflation und niedrigen Zinsen,
                        wie wir sie heute haben, ist es die Vorsorgewohnung, die vergleichsweise höhere Gewinne abwirft.
                        Ein Gleichgewicht zwischen diesen beiden Anlageformen gilt es dann individuell zu finden, ein
                        Mix wird aber immer als profitabel angesehen, allein zur Diversifikation des eigenen
                        Vermögensportfolios.
                    </li>
                </ul>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
                <hr />

                <h2>Nachteile und Risiken – Worauf gilt es bei Vorsorgewohnungen zu achten?</h2>
                <p>
                    Bei allen Vorteilen birgt der Erwerb einer Vorsorgewohnung auch eine Reihe von Nachteile. Auf jeden
                    Fall gilt es, sich die Risiken dieser Anlageoption vor Augen zu führen, um unnötige Kosten zu
                    vermeiden.Dazu zählen:
                </p>
                <ul>
                    <li>
                        <strong>Mietausfall:</strong> Für alle Vermieter das größte Ärgernis: Leerstand, und damit keine
                        Mieteinnahmen. Vor allem bei einer mittels{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredit
                        </Link>{" "}
                        erworbenen, nicht abbezahlten Vorsorgewohnung stellt dies nicht nur einen Einkommensausfall dar,
                        die Kreditzahlung muss eigens geleistet werden, also ein Minus. Daher ist es schon bei der
                        Auswahl des richtigen Erwerbsobjekt unerlässlich, das Risiko so klein wie möglich zu halten. Es
                        wird empfohlen, Objekte in Gebieten mit hohem Mietbedarf zu wählen, die nicht zu groß sind. 40
                        bis 60 m2 und zwei Zimmer können sowohl Singles als auch Paare beziehen und stehen damit hoch im
                        Kurs. Hier bieten sich vor allem Universitätsstädte an. Aspekte wie Verkehrsanbindung (in Wien
                        die U-Bahn) aber auch Nähe zu Grünflächen und Einkaufsmöglichkeiten sollten bei der Suche mit
                        hineinspielen. Genauso kann man sich gegen Mietausfälle über eine Mietausfallversicherung
                        schützen, die natürlich Kosten mit sich trägt, oder über den sogenannten Mietpool. Wohnkomplexe
                        mit mehreren Eigentümern verteilen über den Mietpool anteilig die Mieteinnahmen des ganzen
                        Komplexes, ob die eigens besessene Wohnung nun gerade leer steht oder nicht. Das kann sowohl
                        eine Sicherheit wie auch ein Risiko darstellen.
                    </li>
                    <li>
                        <strong>Überbewertete Wohnungen:</strong> Gerade beim ersten Wohnungskauf kann es passieren,
                        dass man sich über den wirklichen Wert der Wohnung täuscht. Versteckte Renovierungs- und
                        Sanierungskosten etwa können ein vermeintliches Schnäppchen doch gar nicht mehr so attraktiv
                        erscheinen lassen. Ruhe und Geduld, viele Wohnungsbesichtigen und im Zweifel der Rat eines
                        Experten sind unerlässlich, um die richtige Kaufentscheidung zu treffen.
                    </li>
                    <li>
                        <strong>Übernahme von Vermieterpflichten:</strong> Nebst des potentiell zeitlichen Aufwandes
                        (Suche nach Mietern, Eigentümerversammlungen …) können einen als Eigentümer von Wohneinheiten
                        auch Verpflichtungen zur Mitinstandhaltung eines ganzen Wohnkomplexes ereilen. Versteckte
                        umgelegte Sanierungskosten können hier den Wert der eigentlich guten Investition in die
                        Vorsorgewohnung schmälern.
                    </li>
                    <li>
                        <strong>Zinsrisiko:</strong> Ein Grund für die derzeitige Attraktivität von Vorsorgewohnungen
                        ist mit Sicherheit das niedrige Zinsniveau für Kredite. Hat man aber zur Finanzierung einen{" "}
                        <Link to="/artikel/kredit/" target="_blank" rel="noreferrer noopener">
                            Kredit
                        </Link>{" "}
                        mit variablen Zinsen abgeschlossen und steigen diese, kann es zu Renditeneinbußen kommen. In der
                        jetzigen Lage ist diese Steigerung nicht abzusehen, trotzdem sollte man sich bei der Kalkulation
                        seines Finanzierungsplans über dieses Risiko im Klaren sein.
                    </li>
                    <li>
                        <strong>Kapitalbindung:</strong> Um die steuerlichen Vorteile des Erwerbs einer Vorsorgewohnung
                        gänzlich ausnutzen zu können, ist die Veräußerung der Wohnung zwanzig Jahre lang nicht möglich.
                        Sonst gibt es nachträgliche Forderungen vom Finanzamt, wie du hier nachlesen kannst. Das ist ein
                        großer Unterschied zu Anlagen am Finanzmarkt, wo Kapital für Privatanleger ständig und ohne
                        Kosten in beliebigen Tranchen wieder flüssig gemacht werden kann. Darüber sollte man sich beim
                        Erwerb einer Vorsorgewohnung im Klaren sein.
                    </li>
                </ul>
                <hr />

                <h2>Fazit - Lohnt sich die Vorsorgewohnung?</h2>
                <p>
                    Mit Blick auf die Risiken und Nachteile des Erwerbs einer Vorsorgewohnung wird klar: Viele Risiken
                    können durch die korrekte Herangehensweise minimiert werden. Andere Nachteile sind unvermeidliche
                    Opportunitätskosten. Wie bei jeder größeren Investitionsentscheidung sind das richtige Abwägen und
                    Sammeln von Information unerlässlich und der Schlüssel für eine gute Anlage. Beachtet man die
                    derzeitigen Top-Konditionen für Kreditnehmer:innen und - nach wie vor - am Immobilienmarkt, gepaart
                    mit bestehenden steuerlichen Vorteilen in Österreich, erscheint die Vorsorgewohnung derzeit als eine
                    der lukrativsten Anlageoptionen für Vermögensaufbau und Altersvorsorge.
                </p>
                <p>
                    <a className="btn" href={extendUrlWithSourceVersion(urls.survey)}>
                        Kreditvergleich anfordern
                    </a>
                </p>
            </Article>
            <MortgageCalculator noTopMargin defaultTerm={35} defaultMortgage={50000} />

            <BreadcrumbList page={"vorsorgewohnung"}></BreadcrumbList>
            <ArticleStructuredData page={"vorsorgewohnung"} heading={"Vorsorgewohnung – Vom Geldwert zum Sachwert"} />
        </Layout>
    );
};

export default Vorsorgewohnung;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: {
                ns: { in: ["header", "footer", "page.artikel.vorsorgewohnung", "mortgageCalc"] }
                language: { eq: $language }
            }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
